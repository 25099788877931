import { Injectable } from '@angular/core';

const selectedGymKey = 'GYM-PORTAL-ID';

@Injectable({ providedIn: 'root' })
export class SelectedGymStorage {
    setSelectedGymId(gymId: string) {
        localStorage.setItem(selectedGymKey, gymId);
    }

    static getSelectedGymId() {
        return localStorage.getItem(selectedGymKey);
    }
}