import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { GymApiService, GymFindResponse } from '../api/gym.api.service';
import { HexToRgbConverter } from './hex-to-rgb-converter';

@Injectable({ providedIn: 'root' })
export class ActiveGymStateHandlerService {
  activeGym = new BehaviorSubject<GymFindResponse | null>(null);

  constructor(
    private gymApiService: GymApiService,
    private hexToRgbConverter: HexToRgbConverter,
  ) {}

  loadActiveGym() {
    return this.gymApiService
      .findGym()
      .pipe(
        map(this.loadBranding.bind(this)),
        map(gym => {
          this.activeGym.next(gym);
          return gym;
        })
      );
  }

  loadBranding(gym: GymFindResponse) {
    if (!gym.branding) {
      return gym;
    }

    const { branding } = gym;

    if (branding.baseColor) {
      document.documentElement.style.setProperty(
        '--app-base-color',
        branding.baseColor
      );
    }

    if (branding.accentColor) {
      document.documentElement.style.setProperty(
        '--app-accent-color',
        branding.accentColor
      );

      const accentColorRgb = this.hexToRgbConverter.convert(branding.accentColor);
      document.documentElement.style.setProperty(
        '--app-accent-color-rgb',
        `${accentColorRgb.r}, ${accentColorRgb.g}, ${accentColorRgb.b}`
      );
    }

    return gym;
  }
}
