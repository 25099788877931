import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { MembershipAthleteAuthorizationInterceptor } from './interceptors/membership-athlete-authorization.interceptor';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { gymSubdomainLoaderInitializer } from './initializers/gym-subdomain-loader.initializer';
import { GymCustomSubdomainHandlerService } from './support/gym-custom-subdomain-handler.service';
import { GymApiService } from './api/gym.api.service';
import { SelectedGymStorage } from './storage/selected-gym.storage';
import { ActiveGymStateHandlerService } from './support/active-gym-state-handler.service';
import { GoogleMapsModule } from '@angular/google-maps';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    GoogleMapsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripePublishableKey),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    GymApiService,
    SelectedGymStorage,
    ActiveGymStateHandlerService,
    {
      provide: APP_INITIALIZER,
      useFactory: gymSubdomainLoaderInitializer,
      multi: true,
      deps: [GymCustomSubdomainHandlerService]
    },
    MembershipAthleteAuthorizationInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: MembershipAthleteAuthorizationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
