import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiURL } from './functions/api-url';
import { OpenGymApiURL } from './functions/open-gym-api-url';

@Injectable({ providedIn: 'root' })
export class GymApiService {
  constructor(private httpClient: HttpClient) {}

  readGyms(input: ReadGymsRequest) {
    return this.httpClient.get<ReadGymsResponse>(ApiURL('/gym'), {
      params: {
        page: input.page,
        resultsPerPage: input.resultsPerPage,
      },
    });
  }

  findGym() {
    return this.httpClient.get<GymFindResponse>(OpenGymApiURL(''));
  }

  findWihtSubdomain(subdomain: string) {
    return this.httpClient.get<GymSubdomainFindResponse>(ApiURL(`/gym/by-subdomain/${subdomain}`));
  }

  findWithPortalSubdomain(subdomain: string) {
    return this.httpClient.get<GymPortalSubdomainFindResponse>(ApiURL(`/gym/by-portal-subdomain/${subdomain}`));
  }
}

export interface ReadGymsRequest {
  page: number;
  resultsPerPage: number;
}

export interface ReadGymsResponse {
  gyms: ReadGymsResponseItem[];
  totalRecords: number;
}

export interface ReadGymsResponseItem {
  id: string;
  name: string;
  iconURL: string | null;
}

export interface GymFindResponse {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  connectedAccountId: string | null;
  paymentAccountId: string;
  iconURL: string | null;
  branding: {
    headerBackground: string | null;
    headerLogo: string | null;
    baseColor: string | null;
    accentColor: string;
  } | null;
  address: {
    streetAddress: string;
    suite: string;
    city: string;
    stateCode: string;
    zipCode: string;
    geolocation?: {
      coordinates: [number, number];
    }
  };
}

export interface GymSubdomainFindResponse {
  id: string;
  name: string;
  logoURL: string | null;
  iconURL: string | null;
  accentColor: string | null;
  baseColor: string | null;
}

export interface GymPortalSubdomainFindResponse {
  id: string;
  name: string;
  logoURL: string | null;
  iconURL: string | null;
}