import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizedUserStorage } from '../storage/authorized-user.storage';

@Injectable()
export class MembershipAthleteAuthorizationInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = AuthorizedUserStorage.getToken();

    if (!token) {
      return next.handle(req);
    }

    const requestWithAuthorizationToken = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(requestWithAuthorizationToken);
  }
}