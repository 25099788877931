const AUTHORIZED_USER_TOKEN_KEY = 'AUTHORIZED_USER_TOKEN';

export class AuthorizedUserStorage {
  static setToken(token: string) {
    sessionStorage.setItem(AUTHORIZED_USER_TOKEN_KEY, token);
  }

  static getToken() {
    return sessionStorage.getItem(AUTHORIZED_USER_TOKEN_KEY);
  }

  static removeToken() {
    return sessionStorage.removeItem(AUTHORIZED_USER_TOKEN_KEY);
  }
}