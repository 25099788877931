import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, mergeMap, of, throwError } from 'rxjs';
import { GymApiService, GymPortalSubdomainFindResponse } from '../api/gym.api.service';
import { SelectedGymStorage } from '../storage/selected-gym.storage';
import { ActiveGymStateHandlerService } from './active-gym-state-handler.service';

const CUSTOM_SUBDOMAIN_REGEX = new RegExp(`[\\W\\w\-\d]+\.${environment.subdomainBase}`);

@Injectable({ providedIn: 'root' })
export class GymCustomSubdomainHandlerService {
  constructor(
    private gymsApiService: GymApiService,
    private selectedGymStorage: SelectedGymStorage,
    private activeGymStateHandlerService: ActiveGymStateHandlerService,
  ) {}

  handle() {
    const { hostname } = window.location;
    const appIsUnderCustomSubdomain = CUSTOM_SUBDOMAIN_REGEX.test(hostname);

    if (!appIsUnderCustomSubdomain) {
      return of(void 0);
    }

    const gymSubdomainSlug = hostname.replace(new RegExp(`\.${environment.subdomainBase}`), '');

    return this
      .gymsApiService
      .findWithPortalSubdomain(gymSubdomainSlug)
      .pipe(
        catchError(err => {
          window.location.replace(environment.appUrl);

          return throwError(() => err);
        }),
        mergeMap(this.setGymFromSubdomain.bind(this))
      );
  }

  private setGymFromSubdomain(gym: GymPortalSubdomainFindResponse) {
    this.selectedGymStorage.setSelectedGymId(gym.id);
    return this.activeGymStateHandlerService.loadActiveGym();
  }
}